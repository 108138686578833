<!--
 * @Author: 梁平贤
 * @Date: 2020-07-04 16:55:01
 * @LastEditors: linshanfeng
 * @LastEditTime: 2023-12-26 10:44:29
 * @Description:通知人
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\notifier\index.vue
-->
<template>
  <div class="notifier-box">
    <div class="notifier-line-box">
      <div class="iconfont notifier-icon">&#xe85e;</div>
      <div class="line-title">通知人</div>
    </div>
    <div class="notifierColumn">
      <div class="list">
          <div class="person-cent">
            <div class="person-item-add-box" @click.stop="clickSelectAlert">
              <div class="person-item-add">
                <div class="iconfont add-icon">&#xec22;</div>
              </div>
              <div class="name">添加</div>
            </div>
            <div class="person-item" v-for="(p, pindex) in resultList" :key="pindex">
              <en-user-logo :user-name="p.name" :image-url="p.userLogo || p.img" size="34"></en-user-logo>
              <span class="name">{{ p.name }}</span>
              <en-icon size="14" name="iconshanchu-liebiao" class="del-item" color="#f76b6b"
                       @click.native="delPerson(pindex)"></en-icon>
            </div>
          </div>
          <!-- <en-result icon-size="small" type="NoData" v-else></en-result> -->
        </div>
    </div>
    <select-person v-if="selectPersonDialogVisible"
                   :dialogVisible.sync="selectPersonDialogVisible"
                   :personData="resultList"
                   name="选择通知人"
                   :multi="true"
                   @refreshList="getPersonList"></select-person>
  </div>
</template>

<script>
import selectPerson from "../flow/components/selectPerson";

export default {
  name: "BMNotifierColumn",
  components: { selectPerson },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showAlert: false,
      resultList: [],
      showContent: [],
      showList: false,
      selectPersonDialogVisible: false// 人员选择弹窗
    };
  },
  methods: {
    /**
     * @description: 获取子组件的数据
     */
    getPersonList(list, show) {
      this.resultList = list.map((item) => {
        const mod = {};
        mod.id = item.id;
        mod.name = item.name || item.text;
        mod.userLogo = item.userLogo || item.icon || "";
        return mod;
      });
      this.businessData.mdInfo.notice.userList = this.resultList;
      this.$nextTick(() => {
        this.showList = show;
        this.showContent.push("1");
      });
    },
    /**
     * @description: 合并选择内容
     */
    uniqueArr(arr1, arr2) {
      arr1 = arr1.concat(arr2);
      const res = new Map();
      return arr1.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    /**
     * @description: 弹出选择人员弹窗
     */
    clickSelectAlert() {
      this.selectPersonDialogVisible = true;
    },
    /**
     * @description: 删除人员人员弹窗
     */
    delPerson(index) {
      this.resultList.splice(index, 1);
      this.businessData.mdInfo.notice.userList = this.resultList;
    },
    /**
     * @description: 点击展开流程列表
     */
    changeShowList(val) {
      this.showList = !!val.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  .notifier-box {
    .notifier-line-box{
      display: flex;
      align-items: center;
      .notifier-icon{
        width: 16px;
        height: 16px;
        color: #3E90FE
      }
      .line-title{
        width: 43px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #1A1C1E;
        margin-left: 8px;
      }
    }
    .notifierColumn{
      margin-top: 10px;
      background: #fff;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      padding: 21px;
      .list {
      // border-top: 1px solid #e8ecf2;
      padding-right: 20px;
      position: relative;
      overflow: hidden;
      text-align: left;
    }
    .person-cent {
      display: flex;
      padding: 10px 0;
      .person-item-add-box{
        display: flex;
        align-items: center;
        flex-direction:column;
        justify-content: center;
        .person-item-add{
            width: 34px;
            height: 34px;
            background: #F5F8FC;
            border: 1px solid #9EC7FE;
            box-shadow: 0px 3px 3px 0px rgba(62,144,254,0.15);
            border-radius: 50%;
            display: flex;
            cursor: pointer;
            justify-content: center;
            .add-icon{
              width: 16px;
              height: 16px;
              color: #3E90FE;
            }
        }
        .name{
          text-align: center;
        }
      }
      .person-item {
        // width: 62px;
        margin: 0 0 0 30px;
        position: relative;
        cursor: pointer;
        .en-user-logo {
          margin: 0 auto;
          display: block;
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 50px;
          text-align: center;
          margin: auto;
        }
        .del-item {
          position: absolute;
          top: -3px;
          right: 0;
          display: none;
        }
        &:hover {
          .del-item {
            display: block;
          }
        }
        span {
          display: block;
          text-align: center;
          color: #636c78;
        }
      }
    }
    .el-collapse {
      border: 0;
    }
    /deep/ .el-collapse-item__header {
      background-color: #f5f7fa;
      border: 0;
    }
    .el-collapse-item:last-child {
      margin-bottom: 0;
    }
    .el-collapse {
      border: 0;
    }
    /deep/ .el-collapse-item__header {
      background-color: #f5f7fa;
      border: 0;
    }
    .el-collapse-item:last-child {
      margin-bottom: 0;
    }
    /deep/ .el-collapse-item__content {
      border-bottom: 0;
      padding-bottom: 0;
      font-size: 12px;
    }
    /deep/ .el-collapse-item__wrap {
      border-bottom: 0;
    }
    }
    .top-head {
      background-color: #f5f7fa;
      height: 48px;
      padding-left: 21px;
      text-align: left;
      zoom: 1;
      &::after {
        content: "";
        display: block;
        clear: both;
      }
      .txts {
        font-weight: bold;
        float: left;
        line-height: 48px;
      }
      .notices-txt {
        float: left;
        background: #f7f0eb;
        color: #fd9a4a;
        padding: 0 10px;
        border-radius: 30px;
        height: 22px;
        line-height: 22px;
        margin: 13px 0 0 10px;
      }
      .head-add {
        float: left;
        margin-left: 8px;
        cursor: pointer;
        .en-icon {
          float: left;
          margin-top: 15px;
        }
        .txts {
          margin-left: 3px;
          color: #636c78;
          font-weight: normal;
        }
      }
      .show-btn {
        float: right;
        color: #66a7e5;
        margin: 16px 18px 0 0;
        cursor: pointer;
      }
    }
  }
</style>
